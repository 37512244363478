import React from "react";
import Logo from "components/UI/Logo/Logo";
import Footers from "components/Footer/Footer";
import FooterMenu from "./FooterMenu";
import FarathLogo from "../../../assets/images/Farath_Holdings-rbg.png";

const Footer = () => {
  return (
    <Footers
      logo={
        <Logo withLink linkTo="/" src={FarathLogo} title="Farath Holdings" />
      }
      menu={<FooterMenu />}
      copyright={`Copyright @ ${new Date().getFullYear()} Farath Holdings.`}
    />
  );
};

export default Footer;
